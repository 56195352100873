import Background from '../../../images/banner-slider-image1.jpg';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

export default class Carousel extends Component {
	// js() {
	//   const owl = jQuery(".banner-slider");
	//   owl.owlCarousel({
	//     items: 1,
	//     singleItem: true,
	//     loop: false,
	//     autoplay: true,
	//     autoplayTimeout: 500,
	//     autoplayHoverPause: false,
	//     dots: false,
	//     nav: false,
	//     center: true,
	//     animateOut: "fadeOut"
	//   });
	// }

	componentDidMount() {
		// this.js;
	}

	render() {
		return (
			<div className="bannerWrapper" style={{ 'margin-bottom': '1px' }} id="siteBanner">
				<div className="banner-slider bannerSliderStyle owl-carousel owl-theme">
					<div
						className="item bannerImage1"
						style={{
							backgroundImage: `url(${require('../../../images/banner-slider-image1.jpg')})`
						}}
					>
						<div className="bannerCaptionWrap container">
							<div className="bannerCaption">
								<div className="bannerCaptionBox">
									<strong
										className="typewrite"
										data-period="1000"
										data-type="[ &quot;Welcome to NCL&quot;, &quot;Move your Career&quot;]"
									>
										<span className="wrap" />
									</strong>
									<Link to="/careers">Apply Now</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bannerArrow ">
					<a className="scrollNav" href="#aboutSection">
						<div className="banenrArrowImg bounce">
							<img src={require('../../../images/banner-arrow.png')} alt="scrol down" />
						</div>

						<span> Start Exploring </span>
					</a>
				</div>
				<div>
					{/* <OwlCarousel ref="car" options={options} events={events}>
            <div>
              <img
                src={require("../../images/banner-slider-image1.jpg")}
                alt="The Last of us"
              />
            </div>
          </OwlCarousel> */}
				</div>
			</div>
		);
	}
}
