import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FacebookShareButton, GooglePlusShareButton, LinkedinShareButton, FacebookShareCount } from 'react-share';

export default class extends Component {
	constructor() {
		super();
	}
	render() {
		const { job, applied } = this.props;
		const url = window.location.origin + '/job-detail/' + job._id;
		const title = 'Apply at NCL Recruitment Portal for the post of ' + job.title;
		return (
			<div className="col-md-12">
				<div className="jobItem">
					<Link to={'/job-detail/' + job._id}>
						<h3>{job.title}</h3>
					</Link>
					<p className="fixRenderHtml">
						<div
							dangerouslySetInnerHTML={{
								__html: job.description
							}}
						/>
					</p>
					<ul className="jobDetailTags">
						<li>
							{job.location && (
								<span>
									<i className="fas fa-map-marker-alt" /> {job.location}
								</span>
							)}
						</li>
						<li>
							<i className="far fa-clock" /> {job.job_type}
						</li>
						<li>
							<i className="far fa-calendar-alt" /> {job.experience} years
						</li>
					</ul>
					<div className="jobListActions">
						<div className="jobShareDropdown">
							<button
								className="dropdown-toggle"
								type="button"
								id="jobShareDpButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<i className="fas fa-share-alt" />
							</button>

							<div className="dropdown-menu" aria-labelledby="jobShareDpButton">
								<ul>
									<li>
										<a>
											<FacebookShareButton url={url} quote={title} className="button">
												<i className="fab fa-facebook-f" />
											</FacebookShareButton>
										</a>
									</li>
									<li>
										<a>
											{/* <i className="fab fa-twitter" /> */}
											<LinkedinShareButton
												url={url}
												title={title}
												description={job.description}
												className="button"
											>
												<i className="fab fa-linkedin" />
											</LinkedinShareButton>
										</a>
									</li>
									<li>
										<a>
											{/* <i className="fab fa-google-plus-g" /> */}
											<GooglePlusShareButton url={url} title={title} className="button">
												<i className="fab fa-google-plus-g" />
											</GooglePlusShareButton>
										</a>
									</li>
								</ul>
							</div>
						</div>
						{applied && (
							<a className="siteBtn1" style={{ color: '#35b75d' }}>
								<span className="verified">
									<i
										className="fa fa-check-circle fa-2x"
										style={{ color: '#35b75d' }}
										title="You've applied for this position"
									/>
								</span>
							</a>
						)}
						{!applied && (
							<Link className="siteBtn" to={'/job-detail/' + job._id}>
								Apply Now
							</Link>
						)}
					</div>
				</div>
			</div>
		);
	}
}
