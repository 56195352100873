import React, { Component } from "react";
import { shortlistApplicant, removeApplicant } from "../../actions/jobActions";
import {
  getJob,
  getUserJobQA,
  saveAdminRemarks,
} from "../../actions/jobActions";
import { getUser } from "../../actions/authActions";
import {
  sendEmailInvitation,
  sendTestInvitation,
} from "../../actions/jobActions";
import { getAllUserTests } from "../../actions/userTestActions";
import { getTests } from "../../actions/testActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import ReactTable from "react-table";

class ViewApplicant extends Component {
  constructor() {
    super();
    this.state = {
      applicant: {},
      job: {},
      userJob: {},
      tests: [],
      remarks: "",
    };
  }

  componentDidMount() {
    this.props.getJob(this.props.match.params.id);
    this.props.getUser(this.props.match.params.applicant_id);
    this.props.getUserJobQA(
      this.props.match.params.id,
      this.props.match.params.applicant_id
    );
    if (!this.props.allUserTests) {
      this.props.getAllUserTests();
    }
    this.props.getTests();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.applicant) {
      this.setState({ applicant: nextProps.applicant });
    }
    if (nextProps.job) {
      this.setState({ job: nextProps.job });
    }
    if (nextProps.userJob) {
      this.setState({
        userJob: nextProps.userJob,
        remarks: nextProps.userJob.remarks,
      });
    }
    debugger;
  }

  getJobTestInfo(job) {
    return this.props.tests.filter((t) => t._id === job.test);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.saveAdminRemarks(
      this.props.match.params.id,
      this.props.match.params.applicant_id,
      this.state.remarks
    );
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getTestInfo(applicant, job) {
    if (this.props.allUserTests) {
      return this.props.allUserTests.filter(
        (ut) => ut.user == applicant._id && ut.test == job.test
      );
    }
  }

  render() {
    const { job, applicant, userJob } = this.state;
    let userTest = this.getTestInfo(applicant, job);

    const educationColumns = [
      {
        Header: "Institutee",
        accessor: "institute",
        filterable: true,
      },
      {
        Header: "Degreee",
        accessor: "degree",
        filterable: true,
      },
      {
        Header: "Percentagee",
        accessor: "percentage",
        filterable: true,
      },
      {
        Header: "CGPAA",
        accessor: "cgpa",
        filterable: true,
      },
      {
        Header: "Fromm",
        accessor: "from",
        filterable: true,
      },
      {
        Header: "Too",
        accessor: "to",
        filterable: true,
      },
    ];

    const careerColumns = [
      {
        Header: "Companyy",
        accessor: "company",
        filterable: true,
      },
      {
        Header: "Positionn",
        accessor: "position",
        filterable: true,
      },
      {
        Header: "Fromm",
        accessor: "from",
        filterable: true,
      },
      {
        Header: "Too",
        accessor: "to",
        filterable: true,
      },
      {
        Header: "Descriptionn",
        accessor: "description",
        filterable: true,
      },
      {
        Header: "Current Jobb",
        accessor: "currentJob",
        filterable: true,
      },
    ];

    let test = this.getJobTestInfo(job)[0];

    return (
      <div>
        <Navbar />
        <div className="container">
          <div className="card card-body bg-light mb-3">
            <div className="row">
              {applicant && (
                <div className="col-6">
                  <p>
                    <span>
                      <strong>Full Name: </strong>
                      {`${applicant.firstName} ${applicant.middleName} ${applicant.lastName} `}
                    </span>
                  </p>

                  {applicant.education && applicant.education[0] && (
                    <p>
                      <span>
                        <strong>CGPA: </strong> {applicant.education[0].cgpa}
                      </span>
                    </p>
                  )}

                  <p />
                  <p>
                    <span>
                      <strong>Email: </strong>
                      {applicant.email}
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>Phone: </strong>
                      {applicant.phoneNumber}
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>Total Experience: </strong>
                      {applicant.totalExperience}
                    </span>
                  </p>
                  <p>
                    {userTest && userTest[0] && test && (
                      <span>
                        <strong style={{ color: "red" }}>Score: </strong>
                        {userTest[0].score} / {test.totalQuestions}
                      </span>
                    )}
                  </p>
                </div>
              )}
              <div className="col-6">
                <div>
                  <form onSubmit={this.onSubmit.bind(this)}>
                    <div className="form-group">
                      <label>Remarks</label>
                      <textarea
                        className="form-control"
                        name="remarks"
                        onChange={this.onChange.bind(this)}
                        value={this.state.remarks}
                        required
                      />
                    </div>
                    <input
                      type="submit"
                      class=" btn btn-success"
                      value="save"
                    />
                  </form>
                </div>
                <br />
                {applicant.avatar && (
                  <div>
                    <span>
                      <strong>Picture: </strong>
                      <img
                        src={applicant.avatar}
                        alt="Picture"
                        height="400"
                        width="400"
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="container">
              <hr />
              <p>
                <span>
                  <strong>Employment Questions: </strong>
                  {userJob &&
                    userJob.answers &&
                    userJob.answers.map((answer) => {
                      return (
                        <div>
                          <span className="text-success1">
                            <div className="text-success">
                              {job &&
                                job.employment_questions &&
                                job.employment_questions.filter(
                                  (q) => q._id == answer.employment_question_id
                                ) &&
                                job.employment_questions.filter(
                                  (q) => q._id == answer.employment_question_id
                                )[0].title}
                            </div>
                            <div>{answer.answer}</div>
                          </span>
                          <hr />
                        </div>
                      );
                    })}
                </span>
              </p>
            </div>
            <div class="row1">
              <div class="">
                <h5 class="display-9">Career History</h5>
                <ReactTable
                  className="-highlight "
                  data={applicant.career}
                  defaultPageSize={3}
                  columns={careerColumns}
                  filterable
                />
              </div>
              <hr />
              <div class="">
                <h5 class="display-9">Education History</h5>
                <ReactTable
                  className="-highlight "
                  defaultPageSize={3}
                  data={applicant.education}
                  columns={educationColumns}
                  filterable
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewApplicant.propTypes = {
  shortlistApplicant: PropTypes.func.isRequired,
  sendEmailInvitation: PropTypes.func.isRequired,
  sendTestInvitation: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getUserJobQA: PropTypes.func.isRequired,
  saveAdminRemarks: PropTypes.func.isRequired,
  getAllUserTests: PropTypes.func.isRequired,
};
export default connect(
  (state) => ({
    job: state.jobsData.job,
    applicant: state.auth.profile,
    userJob: state.jobsData.userJob,
    allUserTests: state.userTestsData.allUserTests,
    tests: state.testsData.tests,
  }),
  {
    shortlistApplicant,
    removeApplicant,
    sendTestInvitation,
    sendEmailInvitation,
    getJob,
    getUser,
    getUserJobQA,
    saveAdminRemarks,
    getAllUserTests,
    getTests,
  }
)(ViewApplicant);
