import React from "react";

export default function About() {
  return (
    <div className="row" id="aboutSection">
      <div
        className="col-lg-5 whyJoinUs wow slideInLeft"
        data-wow-duration="1500ms"
        data-wow-delay="300ms"
      >
        <h2> Why should you join NCL? </h2>
        <p>
          We are an equal opportunity employer, where an individual’s career
          growth is the culmination of their competence, dedication and
          leadership potential. We strongly believe that recruiting and engaging
          the top talent will lead to best practices and bring the company and
          individual one step closer to achieving their goals. We are one of the
          few companies in Pakistan where the majority (nearly 60%) of our
          senior management consists of females. At NCL we offer unique
          opportunities, rewarding experiences and a place where you can create
          a different, more exciting future.
        </p>
      </div>
      <div
        className="col-lg-7 aboutUsTabs wow slideInRight"
        data-wow-duration="1500ms"
        data-wow-delay="300ms"
      >
        <div className="astContentSection">
          <div className="tab-content" id="">
            <div
              className="tab-pane fade show active"
              id="hire"
              role="tabpanel"
              aria-labelledby="hire-tab"
            >
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="astContentImage">
                    <img
                      src={require("../../../images/hire-tab-image.png")}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="astContent">
                    <strong> Hire </strong>
                    <p>
                      Recruiting, engaging and retaining top talent which will
                      result in collaborative growth for both the individual and
                      Company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="develop"
              role="tabpanel"
              aria-labelledby="develop-tab"
            >
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="astContentImage">
                    <img
                      src={require("../../../images/hire-tab-image.png")}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="astContent">
                    <strong> Develop </strong>
                    <p>
                      Investing in training and development to shape future
                      leaders who are aligned to our beliefs and values.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="grow"
              role="tabpanel"
              aria-labelledby="grow-tab"
            >
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="astContentImage">
                    <img
                      src={require("../../../images/hire-tab-image.png")}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="astContent">
                    <strong> Grow </strong>
                    <p>
                      Offering a wide array of career streams and
                      cross-functional opportunities for individuals while
                      providing an environment which is engaging, safe and
                      conducive to continuous learning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="nav aboutUsTabsNav" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="hire-tab"
              data-toggle="tab"
              href="#hire"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Hire
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="develop-tab"
              data-toggle="tab"
              href="#develop"
              role="tab"
              aria-controls="develop"
              aria-selected="false"
            >
              Develop
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="grow-tab"
              data-toggle="tab"
              href="#grow"
              role="tab"
              aria-controls="grow"
              aria-selected="false"
            >
              Grow
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
