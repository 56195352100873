import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Job from './Job'
import { deleteJob, getJobs } from "../../actions/jobActions";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Navbar from "../layout/Navbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-notifications-component/dist/theme.css";
import { withAlert } from "react-alert";

// const caseInsensitiveFilter = (filter, row) => {
//   const filterValue = filter.value.toLowerCase();
//   const rowValue = row._original.title.toLowerCase();
//   return rowValue.includes(filterValue);
// };
class Jobs extends Component {
  // following is ES6 syntax to initialze constructor
  state = {
    jobs: null,
    flash: "",
  };

  onDeleteClick(id) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.deleteJob(id),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  }

  componentDidMount() {
    this.props.getJobs();
    if (this.props.flash !== "") {
      this.props.alert.show(this.props.flash);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jobs) {
      this.setState({ jobs: nextProps.jobs });
    }
    if (nextProps.flash) {
      this.setState({ flash: nextProps.flash });
    }
  }

  render() {
    const { loading } = this.props;

    const { jobs } = this.state;

    const columns = [
      {
        Header: "Title",
        id: "title",
        accessor: (d) => `${d.title} (${d.applicants.length})`,
        // filterMethod: caseInsensitiveFilter,
        // Filter: ({ filter, onChange }) => (
        //   <input
        //     type="text"
        //     placeholder="Search by title"
        //     value={filter ? filter.value : ""}
        //     onChange={(e) => onChange(e.target.value)}
        //   />
        // ),
      },
      {
        id: "Grade",
        Header: "CGPA/Percentage",
        accessor: (d) =>
          `${d.cgpa} ${d.percentage ? " / " + d.percentage + "%" : ""}`,
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Experience",
        accessor: "experience",
      },
      {
        Header: "Skills",
        accessor: "skills",
      },
      {
        Header: () => <span>Actions</span>,
        accessor: "_id",
        Cell: (row) => (
          <div>
            <i
              className="fa fa-trash-alt"
              aria-hidden="true"
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <Link to={"/jobs/edit/" + row.value}>
              <i className="far fa-edit" title="Edit" />
              {/* <button className='btn btn-warning'>Edit</button> */}
            </Link>
            &nbsp;
            <Link to={"/jobs/show/" + row.value}>
              <i className="fas fa-eye" />
            </Link>
          </div>
        ),
      },
    ];

    // let jobsListing;
    let data;
    if (jobs == null || loading) {
      // jobsListing = <Spinner />;
    } else {
      if (jobs.length > 0) {
        // jobsListing = jobs.map(job => <Job key={job._id} job={job} />)
        data = jobs;
      } else {
        // jobsListing = <h4> No jobs found...</h4>;
      }
    }

    return (
      <div>
        <Navbar />
        <div className="profiles">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="new-job">
                  <Link to={`/jobs/new`} className="btn btn-info">
                    New Job
                  </Link>
                </div>
                <h3 className="display-5 text-center">Jobs Listing</h3>
                <p className="lead text-center">Browse and post jobs</p>
                {/* {jobsListing} */}
                <ReactTable
                  className="-highlight "
                  data={data}
                  columns={columns}
                  filterable
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Jobs.propTypes = {
  getJobs: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  jobs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  jobs: state.jobsData.jobs,
  flash: state.jobsData.flash,
});

export default connect(mapStateToProps, { deleteJob, getJobs })(
  withAlert(Jobs)
);
